import { mapToButton } from '@utils/mapper'
import { KenticoChoice, KenticoImage } from '@utils/KontentTypes';
import { mapFirstKenticoImage } from '@utils/Helpers';
import { ImageButtonBannerProps } from './ImageButtonBanner';

interface ImageLinkBannerProps {
    image: {
        value: KenticoImage[]
    }
    title: {
        value: string
    }
    buttonText: {
        value: string
    }
    buttonButtonColorTheme: {
        value: KenticoChoice[]
    }
    buttonButtonType: {
        value: KenticoChoice[]
    }
    buttonUrlSlug: {
        value: string
    }
    imageSize: {
        value: [
            {
                codename: string
            }
        ]
    }
    showTitle: {
        value: [
            {
                name: string
            }
        ]
    }
    showButton: {
        value: [
            {
                name: string
            }
        ]
    }
    imageLink: {
        value: string
    }
}


export const mapToImageLinkBanner = (data: any): ImageButtonBannerProps | undefined => {
    if (data == undefined)
        return undefined;
  
    const { image, title, imageSize, imageLink, showButton, showTitle } = data;
    const isLargeBanner = imageSize && imageSize.value.length > 0 ? imageSize.value[0].codename == 'large_banner' : false;
    const button = mapToButton(data);
    return {
        image: mapFirstKenticoImage(image.value),
        title: title.value,
        button: button,
        isLargeBanner: isLargeBanner,
        imageLink: imageLink?.value,
        showButton: showButton?.value[0]?.name === 'Show' ? true: false,
        showTitle: showTitle?.value[0]?.name === 'Show' ? true: false
    }
}
