import React from "react"

import Sources from "@components/elements/Image/Sources"
import { rules } from "@styles/mq"
import theme from "@styles/theme"

import { BannerContentContainer, BannerImageContainer, BannerImageSegment, BannerImage } from "./ImageButtonBanner.styles"
import KpButton from "@components/elements/KpButton/KpButton"

import { Grid, Container } from "semantic-ui-react"

import { H2 } from '@styles/Global.styles'
import { ButtonType } from '@utils/KontentTypes'

export interface ImageButtonBannerProps {
  image: {
    url: string
    description: string
  }
  title?: string
  button?: ButtonType
  isLargeBanner?: boolean
  showButton?: boolean
  showTitle?: boolean
  imageLink?: string
}

const ImageButtonBanner = ({ image, title, button, isLargeBanner, showButton, showTitle, imageLink }: ImageButtonBannerProps) => {
  const { url, description } = image;
  const lowSrc = `${url}?w=100&auto=format`
  const isLarge = isLargeBanner == undefined ? false : isLargeBanner;

  return (
    <a href={imageLink} >
      <BannerImageSegment>
        <BannerImageContainer>
          <BannerImage
            className="banner"
            description={description}
            fallback={`${url}?w=768&auto=format 1x, ${url}?w=1536&auto=format 2x`}
            lowSrc={lowSrc}
            sources={Sources(url, rules, theme.imageBreakpoints)}
          />
        </BannerImageContainer>
        {
          button && title &&
          <Container>
            <Grid verticalAlign='middle' columns={1} centered>
              <BannerContentContainer isLargeBanner={isLarge}>
                <Grid.Column textAlign='center'>
                  <div>
                    {
                      showTitle && title &&
                      <H2 color={theme.brand.colors.white}>{title}</H2>
                    }
                    {
                      showButton && button.url != "" &&
                      <KpButton id="ibb-next" buttonType={button.type} color={button.theme} link={button.url}>{button.text}</KpButton>
                    }
                  </div>
                </Grid.Column>
              </BannerContentContainer>
            </Grid>
          </Container>
        }

      </BannerImageSegment>
    </a>
  )
}

export default ImageButtonBanner